<template lang="pug">
campaignReport(interfaceName='agency')
</template>

<script>
import campaignReport from '../../components/report/campaignReport';

export default {
	name: 'Index',
	components: {campaignReport},
};
</script>

<style scoped></style>
